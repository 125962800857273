import { StepsProvider } from "react-step-builder";

import StepsForm from "./components/stepForm/stepForm";

import './App.scss';


function App() {

  //alert(window.location.href)

  return (
    <div className="App">
      <StepsProvider>
        <StepsForm />
      </StepsProvider>
    </div>
  );
}



export default App;
