import { useSteps } from "react-step-builder";

import Rating from "../../components/rating/rating"

export default function StepQuatro(props) {
    const stepsState = useSteps();

    function onClickPreviousStep() {
        stepsState.prev()
    }

    function onChangeValue(value) {
        props.onChange('step4', value)
        stepsState.next()
    }

    return (
        <div className="centered-container animated" >
            <span className="step-title center">Legal!</span>
            <span className="subtitle left">E em relação aos <b><u>preços</u></b>?</span>

            <Rating value="1" onChange={onChangeValue} />

            <div className="step-form-actions">
                <span onClick={onClickPreviousStep}><img src="img/back_icon.png"></img> Voltar</span>
            </div>
        </div>
    )
}