import { useEffect } from "react";
import { useSteps } from "react-step-builder";

import logo from '../../assets/logo_dc.svg'
import backdrop from '../../assets/video_bg_dc.mp4'

import './transition.scss'


export default function Transtion() {
    const stepsState = useSteps();

    useEffect(() => {
        setTimeout(() => {
            stepsState.next()
        }, 1000);
    })

    return (
        <div className="centered-container">
            <video className="backdrop-video-container" autoPlay muted>
                <source src={backdrop} type="video/mp4" />
            </video>

            {/* <img className="backdrop-logo" src={logo} /> */}
            <div className="backdrop-header">
                <img className="logo-sm" src={logo} />
            </div>
        </div>
    )
}